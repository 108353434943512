import {
  Component,
  Inject,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { FormBuilder, Validators, FormGroup } from "@angular/forms";
import * as moment from "moment";
import $ from "jquery";
import Tagify from "../../../assets/js/tagify.min";
import DatePicker from "../../../assets/plugins/qforms/bootstrap-datepicker";
import { WorkFlowService } from "src/app/services/work-flow.service";
import { ToastrService } from "ngx-toastr";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { MatRadioChange } from "@angular/material/radio";

declare function activateDatepicker(): any;

@Component({
  selector: "app-approval",
  templateUrl: "./approval.component.html",
  styleUrls: ["./approval.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class ApprovalComponent implements OnInit {
  form: FormGroup;
  beginid: any;
  description: string;
  formid: any;
  workflowid: any;
  nodeid: any;
  nodetype: any;
  workflowname: string;
  workflowstage: string;
  workflowStagesAll: [];
  slcand: any = [];
  userapp: boolean;
  users: any = [];
  groupapp: boolean;
  groups: any = [];
  otherapp: boolean;
  other: any = [];
  rule: any = [];
  sign: boolean;
  updateValue: any = [];
  andArray: any = [];
  panelOpenState: boolean = true;
  cloneCount: any;
  childcount: any;
  data: any;
  formControls: any = [];
  applicableStates: any = [];
  bindslcand: any;
  bindsubCtrl: any;
  applicablestate: any = [];
  possibleValues = [];
  subControls = [];
  userList: any = [];
  slcUserList: any = [];
  userGroups: any = [];
  slcUserGroups: any = [];
  ruleslist: any = [];
  rulefor: string;
  rulereject: string;
  rulecolumn: string;
  rulejournal: string;
  schedulelist: any = [];
  schedule: string = "no";
  timezone_basedon: string = "no";
  timezone: string;
  duedate_basedon: string = "1";
  duedate_days: string;
  duedate_hours: string;
  userSign: boolean = true;
  upddropdown: string = "";
  updcount = 1;
  conditionsValue = {
    text: false,
    number: false,
    dropdown: false,
    date: false,
    time: false,
  };
  hasSubControls: boolean = false;
  conditions: {
    control: number;
    type: string;
    state: string;
    value: any;
    isValid: boolean;
    conditionsValue: any;
    hasSubControls: boolean;
  }[] = [];
  arrsubcontrols: any = [];
  chkusers: boolean = false;
  chkgroups: boolean = true;
  formname: string;
  ispublished: boolean = false;
  chkschedule: boolean = false;
  chktimezone: boolean = false;
  duedate: boolean = true;
  relativedays: string = "1";
  autoApproval: boolean = false;
  autoReject: boolean = false;
  conditionspanel: boolean = false;
  otherApproverCtrl = [];
  isotherlist: any;

  constructor(
    private fb: FormBuilder,
    private api: WorkFlowService,
    private toastr: ToastrService,
    private ngxService: NgxUiLoaderService,
    private dialogRef: MatDialogRef<ApprovalComponent>,
    @Inject(MAT_DIALOG_DATA)
    {
      beginid,
      description,
      formid,
      workflowid,
      nodeid,
      nodetype,
      workflowname,
      workflowstage,
      workflowStagesAll,
      slcand,
      userapp,
      users,
      groupapp,
      groups,
      otherapp,
      other,
      rule,
      schedulelist,
      sign,
      autoapproval,
      autoreject,
      ispublished,
    }
  ) {
    this.beginid = beginid;
    this.description = description;
    this.formid = formid;
    this.workflowid = workflowid;
    this.nodeid = nodeid;
    this.nodetype = nodetype;
    this.workflowname = workflowname;
    this.workflowstage = workflowstage;
    this.workflowStagesAll = workflowStagesAll;
    this.slcand = slcand;
    this.userapp = userapp;
    this.users = users;
    this.groupapp = groupapp;
    this.groups = groups;
    this.otherapp = otherapp;
    this.other = other;
    this.rule = rule;
    this.schedulelist = schedulelist;
    this.sign = sign;
    this.autoApproval = autoapproval;
    this.autoReject = autoreject;
    this.formname = localStorage.getItem("formname");
    this.ispublished = ispublished;

    this.form = fb.group({
      beginid: [beginid],
      description: [description, Validators.required],
      formid: [formid],
      workflowid: [workflowid],
      nodeid: [nodeid],
      workflowname: [workflowname, Validators.required],
      workflowstage: [workflowstage],
      slcand: [slcand],
      userapp: [userapp],
      users: [users],
      groupapp: [groupapp],
      groups: [groups],
      otherapp: [otherapp],
      other: [other],
      rule: [rule],
      rulefor: beginid == 0 ? "" : rule[0][0].wait_for,
      rulereject: beginid == 0 ? "" : rule[0][0].if_rejected,
      rulecolumn: beginid == 0 ? "" : rule[0][0].approval_column,
      rulejournal: beginid == 0 ? "" : rule[0][0].approval_journal,
      schedulelist: [schedulelist],
      schedule:
        beginid == 0
          ? []
          : schedulelist.length > 0
            ? schedulelist[0][0].schedule
            : [],
      timezone_basedon:
        beginid == 0
          ? ""
          : schedulelist.length > 0
            ? schedulelist[0][0].timezone_basedon
            : "",
      timezone:
        beginid == 0
          ? ""
          : schedulelist.length > 0
            ? schedulelist[0][0].timezone
            : "",
      duedate_basedon:
        beginid == 0
          ? ""
          : schedulelist.length > 0
            ? schedulelist[0][0].duedate_basedon
            : "",
      duedate_days:
        beginid == 0
          ? ""
          : schedulelist.length > 0
            ? schedulelist[0][0].duedate_days
            : "",
      duedate_hours:
        beginid == 0
          ? ""
          : schedulelist.length > 0
            ? schedulelist[0][0].duedate_hours
            : "",
      sign: [sign],
      autoapproval: [autoapproval],
      autoreject: [autoreject],
      ispublished: [ispublished],
    });
    //if (this.workflowstage != "") {
    // this.form.controls.workflowstage.disable();
    //}
    if (beginid !== 0) {
      let newusers = this.users;
      let newgroups = this.groups;
      this.users = [];
      this.groups = [];
      newusers.forEach((element) => {
        this.users.push(element.email);
      });

      newgroups.forEach((element) => {
        this.groups.push(element.role);
      });
      if (this.schedulelist.length > 0) {
        this.chkschedule = true;
        if (schedulelist[0][0].schedule == "yes") {
          this.schedule = "yes";
        }
        if (schedulelist[0][0].timezone_basedon == "yes") {
          this.chktimezone = true;
          this.timezone_basedon = "yes";
        }

        if (schedulelist[0][0].duedate_basedon == 2) {
          this.duedate = false;
          this.duedate_basedon = "2";
        }
        this.relativedays = schedulelist[0][0].relativedays;
      } else {
        this.schedule = "no";
      }
    }

    this.cloneCount = 2;
    this.childcount = 1000;
  }

  ngOnInit(): void {
    const initConditions = [
      {
        control: 0,
        type: "",
        state: "",
        value: null,
        isValid: true,
        conditionsValue: {},
        hasSubControls: false,
      },
    ];

    this.conditions = initConditions;
    if (this.description === "approval") {
      this.description = "Approval";
    }
    this.getControls();
    this.getUserList();
    this.getUserGroups();
    if (this.beginid != 0) {
      this.bindTypesOnUpdate();
      activateDatepicker();
      let parentid = 0;
      let chkcondand = false;
      let chkcondor = false;
      this.form.value.rulecolumn = this.rule[0][0].approval_column;
      this.userSign = this.sign;
      if (this.otherapp) this.isotherlist = this.other;

      for (let x = 0; x < this.slcand.length; x++) {
        if (this.slcand[x][0].state == "and") {
          parentid += 1;
          chkcondand = true;
          chkcondor = false;
        }
        if (this.slcand[x][0].state == "or") {
          parentid += 1;
          chkcondor = true;
          chkcondand = false;
        }
        if (this.slcand[x][0].subcontrol) {
          this.arrsubcontrols = this.slcand[x][0].subcontrol;
        } else {
          this.arrsubcontrols = false;
        }
        if (chkcondand) {
          this.updateValue.push({
            condition_id: this.slcand[x][0].condition_id,
            control_id: this.slcand[x][0].control_id,
            condition_type: this.slcand[x][0].condition_type,
            control: this.slcand[x][0].control,
            subcontrol: this.arrsubcontrols,
            state: this.slcand[x][0].state,
            value: this.slcand[x][0].value,
            dataid: "clone-box-and-" + parentid,
            dataparentid: "clone-box-and-" + parentid,
          });
        }
        if (chkcondor) {
          this.updateValue.push({
            condition_id: this.slcand[x][0].condition_id,
            control_id: this.slcand[x][0].control_id,
            condition_type: this.slcand[x][0].condition_type,
            control: this.slcand[x][0].control,
            subcontrol: this.arrsubcontrols,
            state: this.slcand[x][0].state,
            value: this.slcand[x][0].value,
            dataid: "clone-box-mor-" + parentid,
            dataparentid: "clone-box-mor-" + parentid,
          });
        }
      }
    }
  }

  getControls() {
    this.api.getControls(this.formid).subscribe((res: any) => {
      for (let i = 0; i < res.controls.length; i++) {
        if (
          res.controls[i].controlType != "header" &&
          res.controls[i].controlType != "button" &&
          res.controls[i].controlType != "sectionbreak" &&
          res.controls[i].controlType != "pagenavigator" &&
          res.controls[i].controlType != "social" &&
          res.controls[i].controlType != "divider" &&
          res.controls[i].controlType != "formheader" &&
          res.controls[i].controlType != "footer" &&
          res.controls[i].controlType != "image" &&
          res.controls[i].controlType != "typoparagraph" &&
          res.controls[i].controlType != "twocolumntext" &&
          res.controls[i].controlType != "imagetext" &&
          res.controls[i].controlType != "jumbotron" &&
          res.controls[i].controlType != "features" &&
          res.controls[i].controlType != "servicelist" &&
          res.controls[i].controlType != "pricetable" &&
          res.controls[i].controlType != "list" &&
          res.controls[i].controlType != "groups" &&
          res.controls[i].controlType != "tablelayout" &&
          res.controls[i].controlType != "groupcontrol" &&
          res.controls[i].controlType != "rankingchoice" &&
          res.controls[i].controlType != "age" &&
          res.controls[i].controlType != "signature" &&
          res.controls[i].controlType != "barcode" &&
          res.controls[i].controlType != "expressionbox" &&
          res.controls[i].controlType != "recaptcha" &&
          res.controls[i].controlType != "fingerprint" &&
          res.controls[i].controlType != "Takephoto" &&
          res.controls[i].controlType != "geolocation" &&
          res.controls[i].controlType != "locationcoordinates" &&
          res.controls[i].controlType != "video" &&
          res.controls[i].controlType != "videoRecorder" &&
          res.controls[i].controlType != "fileupload" &&
          res.controls[i].controlType != "imagecropper" &&
          res.controls[i].controlType != "pagebreak"
        ) {
          this.formControls.push(res.controls[i]);
          if (i == 0) {
            this.setApplicableStates(res.controls[i].controlType);
          }
        }
        if (
          res.controls[i].controlType == "dropdown" ||
          res.controls[i].controlType == "email"
        ) {
          if (res.controls[i].controlType == "email") {
            this.otherApproverCtrl.push({
              controlId: res.controls[i].id,
              controlType: res.controls[i].controlType,
              controlName:
                res.controls[i].controlType + "_" + res.controls[i].id,
            });
            if (this.beginid == 0)
              this.isotherlist =
                res.controls[i].controlType + "_" + res.controls[i].id;
          }
          if (res.controls[i].controlType == "dropdown") {
            let chkEmail = true;
            res.controls[i].choices.forEach((elmOption) => {
              if (!this.isEmailValid(elmOption.option)) {
                chkEmail = false;
              }
            });
            if (chkEmail) {
              this.otherApproverCtrl.push({
                controlId: res.controls[i].id,
                controlType: res.controls[i].controlType,
                controlName:
                  res.controls[i].controlType + "_" + res.controls[i].id,
              });
            }
          }
        }
      }
    });
  }

  isEmailValid(email: string): boolean {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(email);
  }

  getApproverCtrl(event) {
    this.isotherlist =
      event.target["options"][event.target["options"].selectedIndex].value;
  }

  scheduleChange(event) {
    this.chkschedule = event.target.value == "yes" ? true : false;
  }

  timezoneChange(event) {
    this.timezone_basedon = event.target.value;
    this.chktimezone = event.target.value == "yes" ? true : false;
  }
  duedateChange(event) {
    this.duedate_basedon = event.target.value;
    this.duedate = event.target.value == "1" ? true : false;
  }
  relativedaysChange(event) {
    this.relativedays = event.target.value;
  }

  getUserList() {
    this.api.getUserlist(this.formid).subscribe((res: any) => {
      let users = res;
      const filteredUsers = users.filter(user =>
        !(user.userRoles.length === 1 && user.userRoles[0] === "BillingAdministrator")
      );

      for (let i = 0; i < filteredUsers.length; i++) {
        //this.userList.push(res[i].email);

        this.userList.push({
          value: filteredUsers[i].email,
          id: filteredUsers[i]._id,
          email: filteredUsers[i].email,
          name: filteredUsers[i].name,
        });
      }
      this.bindTagifyUserList(this.userList);
    });
  }
  getUserGroups() {
    this.api.getUserGroups(this.formid).subscribe((res: any) => {
      let usergroup = res;
      const filteredUserGroups = usergroup.filter(group => group.role != "BillingAdministrator");
      for (let i = 0; i < filteredUserGroups.length; i++) {
        //this.userGroups.push(res[i].role);
        this.userGroups.push({
          value: filteredUserGroups[i].role,
          id: filteredUserGroups[i]._id,
          role: filteredUserGroups[i].role,
          type: filteredUserGroups[i].type,
        });
      }
      this.bindTagifyUserGroups(this.userGroups);
    });
  }

  bindTagifyUserList(data) {
    new Tagify(document.querySelector('[name="tagsuser"]'), {
      whitelist: data,
      enforceWhitelist: true,
      dropdown: {
        searchKeys: ["name", "email"],
        enabled: 0,
        closeOnSelect: !1,
        position: "text",
        highlightFirst: true,
      },
    });
  }
  bindTagifyUserGroups(data) {
    new Tagify(document.querySelector('[name="tagsgroups"]'), {
      whitelist: data,
      dropdown: {
        searchKeys: ["role"],
        enabled: 0,
        closeOnSelect: !1,
        position: "text",
        highlightFirst: true,
      },
    });
  }

  setApplicableStates(controlType: string) {
    this.api.getControlMappingsForRules().subscribe(
      (res: any) => {
        res.states.forEach((control, i) => {
          if (res.states[i].controlType == controlType) {
            for (let x = 0; x < res.states[i].states.length; x++) {
              this.applicableStates.push(res.states[i].states[x]);
            }
          }
        });
      },
      (error) => { }
    );
  }

  bindTypesOnUpdate() {
    this.api.getControlMappingsForRules().subscribe((res: any) => {
      this.bindTypeResponse(res.states);
    });
  }

  bindTypeResponse(resp) {
    let response = JSON.parse(JSON.stringify(resp));
    for (let x = 0; x < response.length; x++) {
      this.applicablestate.push({
        _id: response[x]._id,
        controlType: response[x].controlType,
        states: response[x].states,
      });
    }
  }

  ngAfterViewInit(): void {
    var self = this;
    this.generateFields(self, this.cloneCount, this.childcount);
    this.invokescript(self);

    this.applicablestate = [];
    this.removeTags();
  }

  setConditionOnControlState(state, type) {
    // 1 - filled,
    // 2 - empty
    // 3 - valequalsto
    switch (state.trim()) {
      case "is equal to":
      case "is not equal to":
      case "contains":
      case "does not contain":
      case "begins with":
      case "ends with":
        switch (type) {
          case "textbox":
          case "paragraph":
          case "email":
          case "phone":
          case "name":
          case "address":
          case "website":
            Object.keys(this.conditionsValue).forEach(
              (k) => (this.conditionsValue[k] = false)
            );
            this.conditionsValue.text = true;
            break;
          case "numberbox":
          case "price":
          case "sliderquestiontype":
          case "phone":
          case "website":
            Object.keys(this.conditionsValue).forEach(
              (k) => (this.conditionsValue[k] = false)
            );
            this.conditionsValue.number = true;
            break;
          case "date":
            Object.keys(this.conditionsValue).forEach(
              (k) => (this.conditionsValue[k] = false)
            );
            this.conditionsValue.date = true;
            break;
          case "time":
            Object.keys(this.conditionsValue).forEach(
              (k) => (this.conditionsValue[k] = false)
            );
            this.conditionsValue.time = true;
            break;
        }
        break;
      case "is greater than":
      case "is less than":
        switch (type) {
          case "numberbox":
          case "price":
          case "sliderquestiontype":
            Object.keys(this.conditionsValue).forEach(
              (k) => (this.conditionsValue[k] = false)
            );
            this.conditionsValue.number = true;
            break;
          case "date":
            Object.keys(this.conditionsValue).forEach(
              (k) => (this.conditionsValue[k] = false)
            );
            this.conditionsValue.date = true;
            break;
          case "time":
            Object.keys(this.conditionsValue).forEach(
              (k) => (this.conditionsValue[k] = false)
            );
            this.conditionsValue.time = true;
            break;
        }
        break;
      case "is selected":
      case "is not selected":
        switch (type) {
          case "checkboxes":
          case "dropdown":
          case "singlechoice":
            Object.keys(this.conditionsValue).forEach(
              (k) => (this.conditionsValue[k] = false)
            );
            this.conditionsValue.dropdown = true;
            this.setPossibleValues(type, "");
            break;
        }
        break;
      default:
        Object.keys(this.conditionsValue).forEach(
          (k) => (this.conditionsValue[k] = false)
        );
    }
  }
  setPossibleValues(type, typeval) {
    if (typeval != "") {
      this.formControls.forEach((ctrl) => {
        if (ctrl.controlType === type) {
          switch (ctrl.controlType) {
            case "checkboxes":
            case "singlechoice":
            case "dropdown":
              if (ctrl.id == typeval) {
                this.possibleValues = [];
                ctrl.choices.forEach((element) => {
                  this.possibleValues.push({
                    value: element.option,
                    viewValue: element.option,
                  });
                });
                console.log(this.possibleValues);
              }

              break;
          }
        }
      });
    }
  }

  setSubControls(ctrlType) {
    this.hasSubControls = false;
    switch (ctrlType) {
      case "name":
        this.hasSubControls = true;
        this.setSubcontrolsVal(ctrlType);
        break;
      case "address":
        this.hasSubControls = true;
        this.setSubcontrolsVal(ctrlType);
        break;
    }
  }
  setSubcontrolsVal(type) {
    this.formControls.forEach((ctrl) => {
      if (ctrl.controlType === type) {
        switch (ctrl.controlType) {
          case "name":
            if (Number(ctrl.nameFormat) === 0) {
              this.subControls = [];
              this.subControls.push({
                value: "firstName",
                viewValue: "First Name",
              });
              this.subControls.push({
                value: "lastName",
                viewValue: "Last Name",
              });
            } else if (Number(ctrl.nameFormat) === 1) {
              this.subControls = [];
              this.subControls.push({
                value: "title",
                viewValue: "Title",
              });
              this.subControls.push({
                value: "firstName",
                viewValue: "First Name",
              });
              this.subControls.push({
                value: "lastName",
                viewValue: "Last Name",
              });
            } else if (Number(ctrl.nameFormat) === 2) {
              this.subControls = [];
              this.subControls.push({
                value: "firstName",
                viewValue: "First Name",
              });
              this.subControls.push({
                value: "middleName",
                viewValue: "Middle Name",
              });
              this.subControls.push({
                value: "lastName",
                viewValue: "Last Name",
              });
            } else if (Number(ctrl.nameFormat) === 3) {
              this.subControls = [];
              this.subControls.push({
                value: "title",
                viewValue: "Title",
              });
              this.subControls.push({
                value: "firstName",
                viewValue: "First Name",
              });
              this.subControls.push({
                value: "middleName",
                viewValue: "Middle Name",
              });
              this.subControls.push({
                value: "lastName",
                viewValue: "Last Name",
              });
            }
            break;
          case "address":
            this.subControls = [];
            this.subControls.push({
              value: "addressLine1",
              viewValue: "Address Line 1",
            });
            if (!ctrl.hideAddressLine2) {
              this.subControls.push({
                value: "addressLine2",
                viewValue: "Address Line 2",
              });
            }
            this.subControls.push({
              value: "district",
              viewValue: "District",
            });
            this.subControls.push({
              value: "state",
              viewValue: "State",
            });
            this.subControls.push({
              value: "zip",
              viewValue: "Zip / Pin code",
            });
            break;
        }
      }
    });
  }

  onDisableSignature(event) {
    this.userSign = event.target.checked;
  }
  onAutoApproval(event) {
    this.autoApproval = event.target.checked;
  }

  onAutoReject(event) {
    this.autoReject = event.target.checked;
  }

  getcloneboxid(ctype, boxid) {
    return "clone-box-" + ctype + "-" + boxid;
  }

  getclonechild(boxid) {
    return "dci-" + boxid;
  }

  generateFields(self, cloneCount, childCount) {
    //Main AND condition
    $(document)
      .off("click", "#btn_cond_and")
      .on("click", "#btn_cond_and", function (e) {
        cloneCount += 1;
        $(document).find(".MainAnd").removeClass("hide");
        var $firstrow = $(".MainAnd").find("tr:first").clone();
        var $lastRow = $("[class$=MainAnd] tr:last");
        var $newRow = $lastRow.clone();
        $newRow.find(":text").val("");
        $firstrow.attr("id", "rwand-" + cloneCount);
        $firstrow.attr("data-main-id", "clone-box-and-" + cloneCount);
        $newRow.attr("id", "clone-box-and-" + cloneCount);

        var parentid, childid;
        if ($("[class$=filerTable] tr:last").attr("id")) {
          parentid = $("[class$=filerTable] tr:last").attr("id");

          $(document)
            .find("#" + parentid)
            .after($firstrow);

          $(document)
            .find("#rwand-" + cloneCount)
            .after($newRow);
        } else {
          parentid = $("[class$=filerTable] tr:last").attr("data-parent-id");
          childid = $("[class$=filerTable] tr:last").attr("data-child-id");
          $(document)
            .find("[data-child-id='" + childid + "']")
            .after($firstrow);

          $(document)
            .find("#rwand-" + cloneCount)
            .after($newRow);
        }

        $(document).find(".MainAnd").addClass("hide");
      });

    /* Child AND Condition  */
    $(document)
      .off("click", ".btn_child_and")
      .on("click", ".btn_child_and", function (e) {
        var rowid, childid;
        var datachildid = false;
        if ($(this).closest("tr").attr("id")) {
          rowid = $(this).closest("tr").attr("id");
          datachildid = false;
        } else {
          rowid = $(this).closest("tr").attr("data-parent-id");
          childid = $(this).closest("tr").attr("data-child-id");
          datachildid = true;
        }

        $(document).find(".filterAndTable").removeClass("hide");
        var $lastRow = $("[class$=filterAndTable] tr:not():last");
        var $newRow = $lastRow.clone();
        $newRow.attr("data-parent-id", rowid);
        $newRow.attr("data-child-id", "dci-" + childCount++);
        $newRow.find(":text").val("");
        datachildid
          ? $(document)
            .find("[data-child-id='" + childid + "']")
            .after($newRow)
          : $(document)
            .find("#" + rowid)
            .after($newRow);
        $(document).find(".filterAndTable").addClass("hide");
      });

    //Delete on parent button
    $(document)
      .off("click", ".btn_parent_del")
      .on("click", ".btn_parent_del", function (e) {
        let colpcount = 0;
        var rowid = $(this).closest("tr").attr("data-main-id");
        $("table.filerTable tr").each(function () {
          if ($(this).attr("data-main-id")) {
            colpcount += 1;
          }
        });
        if (colpcount > 1) {
          $('[data-parent-id="' + rowid + '"]').each(function () {
            $(this).closest("tr").remove();
          });
          $('[data-main-id="' + rowid + '"]').each(function () {
            $(this).closest("tr").remove();
          });
          $("#" + rowid).remove();
        }
      });
    //click on delete condition
    $(document)
      .off("click", ".deleteButton")
      .on("click", ".deleteButton", function (e) {
        if ($(this).closest("tr").attr("data-parent-id")) {
          var rowid = $(this).closest("tr").attr("data-parent-id");
          let delcolidcount = 0,
            colpcount = 0;

          $('[data-parent-id="' + rowid + '"]').each(function () {
            delcolidcount += 1;
          });
          $("table.filerTable tr").each(function () {
            if ($(this).attr("id")) {
              colpcount += 1;
            }
          });
          if (delcolidcount == 0 && colpcount > 2) {
            $(this).closest("tr").prev("tr").remove();
          }
          $(this).closest("tr").remove();
        } else {
          var rowid = $(this).closest("tr").attr("id");

          if ($(this).closest("tr").attr("id")) {
            let delcolidcount = 0;
            $('[data-parent-id="' + rowid + '"]').each(function () {
              delcolidcount += 1;
            });
            let colpcount = 0;
            $("table.filerTable tr").each(function () {
              if ($(this).attr("id")) {
                colpcount += 1;
              }
            });
            if (delcolidcount == 0 && colpcount > 2) {
              $(this).closest("tr").prev("tr").remove();
            }
            if (colpcount > 2) {
              $(this).closest("tr").remove();
            }
          }
        }
      });

    //click on Main OR condition
    $(document)
      .off("click", "#btn_cond_or")
      .on("click", "#btn_cond_or", function (e) {
        cloneCount += 1;
        $(document).find(".rwand").removeClass("hide");
        $(document).find(".rwor").removeClass("hide");
        $(document).find(".MainOrTable").removeClass("hide");
        var $firstrow = $(".MainOrTable").find("tr:first").clone();
        var $copiedRow = $("[class$=MainOrTable] tr:last");
        var $newRow = $copiedRow.clone();
        $firstrow.attr("id", "rwor-" + cloneCount);
        $firstrow.attr("data-main-id", "clone-box-mor-" + cloneCount);
        $newRow.attr("id", "clone-box-mor-" + cloneCount);
        $newRow.find(":text").val("");

        var parentid, childid;
        if ($("[class$=filerTable] tr:last").attr("id")) {
          parentid = $("[class$=filerTable] tr:last").attr("id");
          $(document)
            .find("#" + parentid)
            .after($firstrow);

          $(document)
            .find("#rwor-" + cloneCount)
            .after($newRow);
        } else {
          parentid = $("[class$=filerTable] tr:last").attr("data-parent-id");
          childid = $("[class$=filerTable] tr:last").attr("data-child-id");
          $(document)
            .find("[data-child-id='" + childid + "']")
            .after($firstrow);

          $(document)
            .find("#rwor-" + cloneCount)
            .after($newRow);
        }

        $(document).find(".MainOrTable").addClass("hide");
      });

    //Child OR condition
    $(document)
      .off("click", ".btn_child_or")
      .on("click", ".btn_child_or", function (e) {
        var rowid, childid;
        var datachildid = false;
        if ($(this).closest("tr").attr("id")) {
          rowid = $(this).closest("tr").attr("id");
          datachildid = false;
        } else {
          rowid = $(this).closest("tr").attr("data-parent-id");
          childid = $(this).closest("tr").attr("data-child-id");
          datachildid = true;
        }

        $(document).find(".filterOrTable").removeClass("hide");
        var $lastRow = $("[class$=filterOrTable] tr:not():last");
        var $newRow = $lastRow.clone();
        $newRow.attr("data-parent-id", rowid);
        $newRow.attr("data-child-id", "dci-" + childCount++);
        $newRow.find(":text").val("");
        datachildid
          ? $(document)
            .find("[data-child-id='" + childid + "']")
            .after($newRow)
          : $(document)
            .find("#" + rowid)
            .after($newRow);

        $(document).find(".filterOrTable").addClass("hide");
      });
  }

  invokescript(_self) {
    $(document).on("change", ".slc-cnd-name", function (e) {
      let controlType = e.target.value;

      let selectedI = this.selectedIndex;
      let ctrlValue = this.options[selectedI].dataset.id;
      _self.api
        .getControlMappingsForRules(controlType)
        .subscribe((res: any) => {
          _self.bindslcand = "";
          _self.bindsubCtrl = "";
          let rowid = "0";
          _self.setSubControls(controlType);
          _self.setPossibleValues(controlType, ctrlValue);

          if (e.currentTarget.closest("tr").hasAttribute("id")) {
            rowid = e.currentTarget.closest("tr").getAttribute("id");
          } else {
            rowid = e.currentTarget.closest("tr").getAttribute("data-child-id");
          }

          for (let x = 0; x < _self.subControls.length; x++) {
            _self.bindsubCtrl +=
              "<option value=" +
              _self.subControls[x].value +
              ">" +
              _self.subControls[x].viewValue +
              "</option>";
          }
          for (let x = 0; x < res.states.length; x++) {
            if (controlType == res.states[x].controlType) {
              for (let y = 0; y < res.states[x].states.length; y++) {
                _self.bindslcand +=
                  "<option>" + res.states[x].states[y] + "</option>";
              }
            }
          }

          if ($(this).closest("tr").attr("id")) {
            if (_self.hasSubControls)
              $(this).closest("tr").find("td:eq(2)").removeClass("hide");
            else $(this).closest("tr").find("td:eq(2)").addClass("hide");
          } else {
            if (_self.hasSubControls)
              $(this).closest("tr").find("td:eq(3)").removeClass("hide");
            else $(this).closest("tr").find("td:eq(3)").addClass("hide");
          }

          var itable = document.getElementById(
            "filerTable"
          ) as HTMLTableElement;
          for (let i in itable.rows) {
            let irow = itable.rows[i];
            for (let j in irow.cells) {
              if (j == "0") {
                if (irow.hasAttribute("id")) {
                  let tablerowid = irow.getAttribute("id");
                  if (rowid == tablerowid) {
                    $(irow)
                      .find("td:eq(3) select.slc-cnd-type")
                      .html(_self.bindslcand);
                    $(irow)
                      .find("td:eq(2) select.slc_subcontrols")
                      .html(_self.bindsubCtrl);
                    $(irow)
                      .find("td:eq(3) select.slc-cnd-type")
                      .trigger("change");
                  }
                }
                if (irow.hasAttribute("data-child-id")) {
                  let tablerowid = irow.getAttribute("data-child-id");
                  if (rowid == tablerowid) {
                    $(irow)
                      .find("td:eq(4) select.slc-cnd-type")
                      .html(_self.bindslcand);
                    $(irow)
                      .find("td:eq(3) select.slc_subcontrols")
                      .html(_self.bindsubCtrl);
                    $(irow)
                      .find("td:eq(4) select.slc-cnd-type")
                      .trigger("change");
                  }
                }
              }
            }
          }
        });
    });

    $(document).on("change", ".slc-cnd-type", function (e) {
      var state;
      var ctrltype = e.target.value;
      if (e.currentTarget.closest("tr").hasAttribute("id")) {
        state = $(this)
          .closest("tr")
          .find("td:eq(1) select.form-select>option:checked")
          .val();
      } else {
        state = $(this)
          .closest("tr")
          .find("td:eq(2) select.form-select>option:checked")
          .val();
      }
      _self.setConditionOnControlState(ctrltype, state);
      var txtindex = $(this).closest("tr").find("td").length <= 10 ? 4 : 5;
      var numindex = $(this).closest("tr").find("td").length <= 10 ? 5 : 6;
      var dropdownindex = $(this).closest("tr").find("td").length <= 10 ? 6 : 7;
      var dateindex = $(this).closest("tr").find("td").length <= 10 ? 7 : 8;
      var timeindex = $(this).closest("tr").find("td").length <= 10 ? 8 : 9;

      switch (_self.conditionsValue.text) {
        case true:
          $(this)
            .closest("tr")
            .find("td:eq(" + txtindex + ")")
            .removeClass("hide");
          break;
        case false:
          $(this)
            .closest("tr")
            .find("td:eq(" + txtindex + ")")
            .addClass("hide");
          break;
      }
      switch (_self.conditionsValue.number) {
        case true:
          $(this)
            .closest("tr")
            .find("td:eq(" + numindex + ")")
            .removeClass("hide");
          break;

        case false:
          $(this)
            .closest("tr")
            .find("td:eq(" + numindex + ")")
            .addClass("hide");
          break;
      }
      switch (_self.conditionsValue.dropdown) {
        case true:
          $(this)
            .closest("tr")
            .find("td:eq(" + dropdownindex + ")")
            .removeClass("hide");
          var slcdropdown = "";
          for (let x = 0; x < _self.possibleValues.length; x++) {
            slcdropdown +=
              "<option>" + _self.possibleValues[x].viewValue + "</option>";
          }
          $(this)
            .closest("tr")
            .find("td:eq(" + dropdownindex + ") select")
            .html(slcdropdown);
          break;

        case false:
          $(this)
            .closest("tr")
            .find("td:eq(" + dropdownindex + ")")
            .addClass("hide");
          break;
      }
      switch (_self.conditionsValue.date) {
        case true:
          $(this)
            .closest("tr")
            .find("td:eq(" + dateindex + ")")
            .removeClass("hide");
          activateDatepicker();

          break;
        case false:
          $(this)
            .closest("tr")
            .find("td:eq(" + dateindex + ")")
            .addClass("hide");
          break;
      }
      switch (_self.conditionsValue.time) {
        case true:
          $(this)
            .closest("tr")
            .find("td:eq(" + timeindex + ")")
            .removeClass("hide");
          activateDatepicker();

          break;
        case false:
          $(this)
            .closest("tr")
            .find("td:eq(" + timeindex + ")")
            .addClass("hide");
          break;
      }
    });

    $(document).on("click", ".slcoptions", function (e) {
      let ctrltype, ctrlVal;
      var stroption = "";
      if (e.currentTarget.closest("tr").hasAttribute("id")) {
        ctrltype = $(this)
          .closest("tr")
          .find("td:eq(1) select.form-select>option:checked")
          .val();
        ctrlVal = $(this)
          .closest("tr")
          .find("td:eq(1) select.form-select>option:checked")
          .attr("data-id");
      } else {
        ctrltype = $(this)
          .closest("tr")
          .find("td:eq(2) select.form-select>option:checked")
          .val();
        ctrlVal = $(this)
          .closest("tr")
          .find("td:eq(2) select.form-select>option:checked")
          .attr("data-id");
      }
      _self.setPossibleValues(ctrltype, ctrlVal);
      for (let x = 0; x < _self.possibleValues.length; x++) {
        if ($(this).val() == _self.possibleValues[x].viewValue) {
          stroption +=
            "<option selected>" +
            _self.possibleValues[x].viewValue +
            "</option>";
        } else {
          stroption +=
            "<option>" + _self.possibleValues[x].viewValue + "</option>";
        }
      }
      $(this).html(stroption);
    });
  }

  removeTags() {
    $(document).on("click", ".datepickerbutton", function (e) {
      $("table.filerTable tr").each(function () {
        $(this)
          .find("td")
          .each(function () {
            $(this).removeAttr("ng-reflect-ng-class");
          });
      });
      activateDatepicker();
    });
  }

  radioChange(event: MatRadioChange) {
    var tagsgroup = document.querySelector('[id="ptagsgroups"]');
    var tagsuser = document.querySelector('[id="ptagsuser"]');
    if (event.value == "groups") {
      tagsgroup.classList.remove("hide");
      tagsuser.classList.add("hide");
      this.groupapp = true;
      this.userapp = false;
      this.otherapp = false;
    } else if (event.value == "users") {
      tagsgroup.classList.add("hide");
      tagsuser.classList.remove("hide");
      this.userapp = true;
      this.groupapp = false;
      this.otherapp = false;
    } else {
      tagsgroup.classList.add("hide");
      tagsuser.classList.add("hide");
      this.userapp = false;
      this.groupapp = false;
      this.otherapp = true;
    }
  }

  saveform(formstate) {
    this.andArray = [];
    let validform = true;
    validform = this.approvalConditions();
    if (this.form.value.workflowname == "") {
      validform = false;
    }
    if (
      this.form.value.rulefor == "" &&
      !this.autoApproval &&
      !this.autoReject
    ) {
      validform = false;
    }
    if (
      this.form.value.rulereject == "" &&
      !this.autoApproval &&
      !this.autoReject
    ) {
      validform = false;
    }

    if (validform) {
      this.data = {
        beginid: this.beginid,
        workflowid: this.form.value.workflowid,
        formid: this.form.value.formid,
        nodeid: this.form.value.nodeid,
        nodetype: this.nodetype,
        formname: this.form.value.workflowname,
        stage: this.form.value.workflowstage,
        slcand: this.andArray,
        isuser: this.userapp,
        approveruser: this.userapp == true ? this.slcUserList : [],
        isgroup: this.groupapp,
        approvergroup: this.groupapp == true ? this.slcUserGroups : [],
        isother: this.otherapp,
        approverother: this.otherapp == true ? this.isotherlist : [],
        ruleslist: this.ruleslist,
        schedulelist: this.schedulelist,
        userSign: this.userSign,
        autoapproval: this.autoApproval,
        autoreject: this.autoReject,
        status: true,
        userid: this.api.getUserId(),
      };

      if (this.beginid === 0 && formstate == "save") {
        this.api.saveApproval(this.data).subscribe(
          (res: any) => {
            this.ngxService.stop();
            if (res && Number(res.responseCode) === 201) {
              this.toastr.success("Approval saved successfully", "Success");
              this.dialogRef.close({ cancel: false, fetch: true });
            } else {
              this.toastr.error("Approval failed! ", "Error!");
            }
            this.ngxService.stop();
          },
          (err) => {
            this.ngxService.stop();
            console.log(err);
          }
        );
      } else {
        this.api.updateApproval(this.data, this.data.beginid).subscribe(
          (res: any) => {
            this.ngxService.stop();
            if (res && Number(res.responseCode) === 201) {
              this.toastr.success("Approval updated successfully", "Success");
            } else {
              this.toastr.error("Approval failed! ", "Error!");
            }
            this.ngxService.stop();
          },
          (err) => {
            this.ngxService.stop();
            console.log(err);
          }
        );
      }
      this.dialogRef.close(this.form.value);
    }
  }
  approvalConditions() {
    let chkcondition = true;
    this.getTagsData();
    this.getRuleData();
    this.getScheculeData();

    if (this.form.value.workflowname == "") {
      this.toastr.error("Enter Approval Name!", "Error!");
      chkcondition = false;
    }
    if (
      this.form.value.rulefor == "" &&
      !this.autoApproval &&
      !this.autoReject
    ) {
      this.toastr.error("Select an option in when wait for!", "Error!");
      chkcondition = false;
    }
    if (
      this.form.value.rulereject == "" &&
      !this.autoApproval &&
      !this.autoReject
    ) {
      this.toastr.error("Select an option in when anyone rejects!", "Error!");
      chkcondition = false;
    }
    if (
      this.slcUserList.length <= 0 &&
      this.slcUserGroups.length <= 0 &&
      this.autoApproval == true
    ) {
      this.toastr.error(
        "Please Select User or Group for Auto Approval!",
        "Error!"
      );
      chkcondition = false;
    }
    if (
      this.slcUserList.length <= 0 &&
      this.slcUserGroups.length <= 0 &&
      this.autoReject == true
    ) {
      this.toastr.error(
        "Please Select User or Group for Auto Reject!",
        "Error!"
      );
      chkcondition = false;
    }

    if (
      this.slcUserList.length == 0 &&
      this.slcUserGroups.length == 0 &&
      this.isotherlist.length == 0
    ) {
      this.toastr.error(
        "Please select your approver or group of approvers!",
        "Error!"
      );
      chkcondition = false;
    }

    if (
      this.slcUserList.length > 0 &&
      this.form.value.rulereject == null &&
      !this.autoApproval &&
      !this.autoReject
    ) {
      this.toastr.error(
        "Please Select User Approvers Reject Condition!",
        "Error!"
      );
      chkcondition = false;
    }
    if (
      this.slcUserGroups.length > 0 &&
      this.form.value.rulereject == null &&
      !this.autoApproval &&
      !this.autoReject
    ) {
      this.toastr.error(
        "Please Select Group Approvers Reject Condition!",
        "Error!"
      );
      chkcondition = false;
    }
    if (
      this.slcUserList.length > 0 &&
      this.form.value.rulefor == null &&
      !this.autoApproval &&
      !this.autoReject
    ) {
      this.toastr.error("Please Select User Approvers Condition!", "Error!");
      chkcondition = false;
    }
    if (
      this.slcUserGroups.length > 0 &&
      this.form.value.rulefor == null &&
      !this.autoApproval &&
      !this.autoReject
    ) {
      this.toastr.error("Please Select Group Approvers Condition!", "Error!");
      chkcondition = false;
    }

    //****************** DO NOT REMOVE THIS CODE ****************************
    // var table = document.getElementById("filerTable") as HTMLTableElement;
    // for (let i in table.rows) {
    //   let row = table.rows[i];
    //   for (let j in row.cells) {
    //     let col = row.cells[j];
    //     if (j == "0") {
    //       if (row.hasAttribute("id")) {
    //         var colcount = $(row).find("td").length;

    //         if (row.classList.contains("trcondand")) {
    //           if (colcount == 10) {
    //             let vcol = this.getValueColumn(
    //               colcount,
    //               $(row)
    //                 .find("td:eq(1) select.form-select>option:checked")
    //                 .val()
    //             );
    //             let chksubctrl = $(row).find("td:eq(2)").hasClass("hide");

    //             if (chksubctrl) {
    //               this.andArray.push([
    //                 {
    //                   condition_id: parseInt(i) + 1,
    //                   control_id: $(row)
    //                     .find("td:eq(1) select.form-select>option:checked")
    //                     .attr("data-id"),
    //                   control: $(row)
    //                     .find("td:eq(1) select.form-select>option:checked")
    //                     .val(),
    //                   condition_type: $(row)
    //                     .find("td:eq(3) select.form-select>option:checked")
    //                     .text(),
    //                   state: "and",
    //                   value:
    //                     $(row).find("td:eq(" + vcol + ") input").length > 0
    //                       ? $(row)
    //                           .find(
    //                             "td:eq(" + vcol + ") input[name='txtcondvalue']"
    //                           )
    //                           .val()
    //                       : $(row)
    //                           .find(
    //                             "td:eq(" +
    //                               vcol +
    //                               ") select.form-select>option:checked"
    //                           )
    //                           .val(),
    //                 },
    //               ]);
    //             } else {
    //               this.andArray.push([
    //                 {
    //                   condition_id: parseInt(i) + 1,
    //                   control_id: $(row)
    //                     .find("td:eq(1) select.form-select>option:checked")
    //                     .attr("data-id"),
    //                   control: $(row)
    //                     .find("td:eq(1) select.form-select>option:checked")
    //                     .val(),
    //                   subcontrol: [
    //                     {
    //                       value: $(row)
    //                         .find("td:eq(2) select.form-select>option:checked")
    //                         .val(),
    //                       text: $(row)
    //                         .find("td:eq(2) select.form-select>option:checked")
    //                         .text(),
    //                     },
    //                   ],
    //                   condition_type: $(row)
    //                     .find("td:eq(3) select.form-select>option:checked")
    //                     .text(),
    //                   state: "and",
    //                   value:
    //                     $(row).find("td:eq(" + vcol + ") input").length > 0
    //                       ? $(row)
    //                           .find(
    //                             "td:eq(" + vcol + ") input[name='txtcondvalue']"
    //                           )
    //                           .val()
    //                       : $(row)
    //                           .find(
    //                             "td:eq(" +
    //                               vcol +
    //                               ") select.form-select>option:checked"
    //                           )
    //                           .val(),
    //                 },
    //               ]);
    //             }
    //             if (
    //               $(row)
    //                 .find("td:eq(3) select.form-select>option:checked")
    //                 .text() == ""
    //             ) {
    //               chkcondition = false;
    //             }
    //           }
    //         }
    //         if (row.classList.contains("trcondmor")) {
    //           if (colcount == 10) {
    //             let vcol = this.getValueColumn(
    //               colcount,
    //               $(row)
    //                 .find("td:eq(1) select.form-select>option:checked")
    //                 .val()
    //             );
    //             let chksubctrl = $(row).find("td:eq(2)").hasClass("hide");
    //             if (chksubctrl) {
    //               this.andArray.push([
    //                 {
    //                   condition_id: parseInt(i) + 1,
    //                   control_id: $(row)
    //                     .find("td:eq(1) select.form-select>option:checked")
    //                     .attr("data-id"),
    //                   control: $(row)
    //                     .find("td:eq(1) select.form-select>option:checked")
    //                     .val(),
    //                   condition_type: $(row)
    //                     .find("td:eq(3) select.form-select>option:checked")
    //                     .text(),
    //                   state: "or",
    //                   value:
    //                     $(row).find("td:eq(" + vcol + ") input").length > 0
    //                       ? $(row)
    //                           .find(
    //                             "td:eq(" + vcol + ") input[name='txtcondvalue']"
    //                           )
    //                           .val()
    //                       : $(row)
    //                           .find(
    //                             "td:eq(" +
    //                               vcol +
    //                               ") select.form-select>option:checked"
    //                           )
    //                           .val(),
    //                 },
    //               ]);
    //             } else {
    //               this.andArray.push([
    //                 {
    //                   condition_id: parseInt(i) + 1,
    //                   control_id: $(row)
    //                     .find("td:eq(1) select.form-select>option:checked")
    //                     .attr("data-id"),
    //                   control: $(row)
    //                     .find("td:eq(1) select.form-select>option:checked")
    //                     .val(),
    //                   subcontrol: [
    //                     {
    //                       value: $(row)
    //                         .find("td:eq(2) select.form-select>option:checked")
    //                         .val(),
    //                       text: $(row)
    //                         .find("td:eq(2) select.form-select>option:checked")
    //                         .text(),
    //                     },
    //                   ],
    //                   condition_type: $(row)
    //                     .find("td:eq(3) select.form-select>option:checked")
    //                     .text(),
    //                   state: "or",
    //                   value:
    //                     $(row).find("td:eq(" + vcol + ") input").length > 0
    //                       ? $(row)
    //                           .find(
    //                             "td:eq(" + vcol + ") input[name='txtcondvalue']"
    //                           )
    //                           .val()
    //                       : $(row)
    //                           .find(
    //                             "td:eq(" +
    //                               vcol +
    //                               ") select.form-select>option:checked"
    //                           )
    //                           .val(),
    //                 },
    //               ]);
    //             }

    //             if (
    //               $(row)
    //                 .find("td:eq(3) select.form-select>option:checked")
    //                 .text() == ""
    //             ) {
    //               chkcondition = false;
    //             }
    //           }
    //         }
    //       }
    //       if (row.hasAttribute("data-parent-id")) {
    //         var colcount = $(row).find("td").length;
    //         if (row.classList.contains("trcondand")) {
    //           if (colcount == 11) {
    //             let vcol = this.getValueColumn(
    //               colcount,
    //               $(row)
    //                 .find("td:eq(2) select.form-select>option:checked")
    //                 .val()
    //             );
    //             let chksubctrl = $(row).find("td:eq(3)").hasClass("hide");
    //             if (chksubctrl) {
    //               this.andArray.push([
    //                 {
    //                   condition_id: parseInt(i) + 1,
    //                   control_id: $(row)
    //                     .find("td:eq(2) select.form-select>option:checked")
    //                     .attr("data-id"),
    //                   control: $(row)
    //                     .find("td:eq(2) select.form-select>option:checked")
    //                     .val(),
    //                   condition_type: $(row)
    //                     .find("td:eq(4) select.form-select>option:checked")
    //                     .text(),
    //                   state: "childand",
    //                   value:
    //                     $(row).find("td:eq(" + vcol + ") input").length > 0
    //                       ? $(row)
    //                           .find(
    //                             "td:eq(" + vcol + ") input[name='txtcondvalue']"
    //                           )
    //                           .val()
    //                       : $(row)
    //                           .find(
    //                             "td:eq(" +
    //                               vcol +
    //                               ") select.form-select>option:checked"
    //                           )
    //                           .val(),
    //                 },
    //               ]);
    //             } else {
    //               this.andArray.push([
    //                 {
    //                   condition_id: parseInt(i) + 1,
    //                   control_id: $(row)
    //                     .find("td:eq(2) select.form-select>option:checked")
    //                     .attr("data-id"),
    //                   control: $(row)
    //                     .find("td:eq(2) select.form-select>option:checked")
    //                     .val(),
    //                   subcontrol: [
    //                     {
    //                       value: $(row)
    //                         .find("td:eq(3) select.form-select>option:checked")
    //                         .val(),
    //                       text: $(row)
    //                         .find("td:eq(3) select.form-select>option:checked")
    //                         .text(),
    //                     },
    //                   ],
    //                   condition_type: $(row)
    //                     .find("td:eq(4) select.form-select>option:checked")
    //                     .text(),
    //                   state: "childand",
    //                   value:
    //                     $(row).find("td:eq(" + vcol + ") input").length > 0
    //                       ? $(row)
    //                           .find(
    //                             "td:eq(" + vcol + ") input[name='txtcondvalue']"
    //                           )
    //                           .val()
    //                       : $(row)
    //                           .find(
    //                             "td:eq(" +
    //                               vcol +
    //                               ") select.form-select>option:checked"
    //                           )
    //                           .val(),
    //                 },
    //               ]);
    //             }

    //             if (
    //               $(row)
    //                 .find("td:eq(4) select.form-select>option:checked")
    //                 .text() == ""
    //             ) {
    //               chkcondition = false;
    //             }
    //           }
    //         }
    //         if (row.classList.contains("trcondor")) {
    //           if (colcount == 11) {
    //             let vcol = this.getValueColumn(
    //               colcount,
    //               $(row)
    //                 .find("td:eq(2) select.form-select>option:checked")
    //                 .val()
    //             );
    //             let chksubctrl = $(row).find("td:eq(3)").hasClass("hide");
    //             if (chksubctrl) {
    //               this.andArray.push([
    //                 {
    //                   condition_id: parseInt(i) + 1,
    //                   control_id: $(row)
    //                     .find("td:eq(2) select.form-select>option:checked")
    //                     .attr("data-id"),
    //                   control: $(row)
    //                     .find("td:eq(2) select.form-select>option:checked")
    //                     .val(),
    //                   condition_type: $(row)
    //                     .find("td:eq(4) select.form-select>option:checked")
    //                     .text(),
    //                   state: "childor",
    //                   value:
    //                     $(row).find("td:eq(" + vcol + ") input").length > 0
    //                       ? $(row)
    //                           .find(
    //                             "td:eq(" + vcol + ") input[name='txtcondvalue']"
    //                           )
    //                           .val()
    //                       : $(row)
    //                           .find(
    //                             "td:eq(" +
    //                               vcol +
    //                               ") select.form-select>option:checked"
    //                           )
    //                           .val(),
    //                 },
    //               ]);
    //             } else {
    //               this.andArray.push([
    //                 {
    //                   condition_id: parseInt(i) + 1,
    //                   control_id: $(row)
    //                     .find("td:eq(2) select.form-select>option:checked")
    //                     .attr("data-id"),
    //                   control: $(row)
    //                     .find("td:eq(2) select.form-select>option:checked")
    //                     .val(),
    //                   subcontrol: [
    //                     {
    //                       value: $(row)
    //                         .find("td:eq(3) select.form-select>option:checked")
    //                         .val(),
    //                       text: $(row)
    //                         .find("td:eq(3) select.form-select>option:checked")
    //                         .text(),
    //                     },
    //                   ],
    //                   condition_type: $(row)
    //                     .find("td:eq(4) select.form-select>option:checked")
    //                     .text(),
    //                   state: "childor",
    //                   value:
    //                     $(row).find("td:eq(" + vcol + ") input").length > 0
    //                       ? $(row)
    //                           .find(
    //                             "td:eq(" + vcol + ") input[name='txtcondvalue']"
    //                           )
    //                           .val()
    //                       : $(row)
    //                           .find(
    //                             "td:eq(" +
    //                               vcol +
    //                               ") select.form-select>option:checked"
    //                           )
    //                           .val(),
    //                 },
    //               ]);
    //             }

    //             if (
    //               $(row)
    //                 .find("td:eq(4) select.form-select>option:checked")
    //                 .text() == ""
    //             ) {
    //               chkcondition = false;
    //             }
    //           }
    //         }
    //       }
    //     }
    //   }
    // }

    return chkcondition;
  }

  getColumnFive(ctrltype, condition_type) {
    let cssclass = "hide";
    if (
      ctrltype == "textbox" ||
      ctrltype == "paragraph" ||
      ctrltype == "name" ||
      ctrltype == "phone" ||
      ctrltype == "address" ||
      ctrltype == "website" ||
      ctrltype == "email" ||
      ctrltype == "matrixchoice"
    ) {
      cssclass = this.getConditionsValueOnUpdate(ctrltype, condition_type);
    }
    return cssclass;
  }
  getColumnSix(ctrltype, condition_type) {
    let cssclass = "hide";
    if (
      ctrltype == "numberbox" ||
      ctrltype == "sliderquestiontype" ||
      ctrltype == "price"
    ) {
      cssclass = this.getConditionsValueOnUpdate(ctrltype, condition_type);
    }
    return cssclass;
  }
  getColumnSeven(ctrltype, condition_type) {
    let cssclass = "hide";
    if (
      ctrltype == "checkboxes" ||
      ctrltype == "singlechoice" ||
      ctrltype == "dropdown"
    ) {
      cssclass = this.getConditionsValueOnUpdate(ctrltype, condition_type);
    }
    return cssclass;
  }
  getColumnEight(ctrltype, condition_type) {
    let cssclass = "hide";
    if (ctrltype == "date") {
      cssclass = this.getConditionsValueOnUpdate(ctrltype, condition_type);
    }
    return cssclass;
  }
  getColumnNine(ctrltype, condition_type) {
    let cssclass = "hide";
    if (ctrltype == "time") {
      cssclass = this.getConditionsValueOnUpdate(ctrltype, condition_type);
    }
    return cssclass;
  }

  getConditionsValueOnUpdate(ctrltype, condition_type) {
    let strclass = "";
    this.setConditionOnControlState(condition_type, ctrltype);
    if (
      ctrltype == "textbox" ||
      ctrltype == "paragraph" ||
      ctrltype == "name" ||
      ctrltype == "phone" ||
      ctrltype == "address" ||
      ctrltype == "website" ||
      ctrltype == "email" ||
      ctrltype == "matrixchoice"
    ) {
      strclass = this.conditionsValue.text ? "" : "hide";
    }
    if (
      ctrltype == "numberbox" ||
      ctrltype == "sliderquestiontype" ||
      ctrltype == "price"
    ) {
      strclass = this.conditionsValue.number ? "" : "hide";
    }
    if (
      ctrltype == "checkboxes" ||
      ctrltype == "singlechoice" ||
      ctrltype == "dropdown"
    ) {
      strclass = this.conditionsValue.dropdown ? "" : "hide";
    }
    if (ctrltype == "date") {
      strclass = this.conditionsValue.date ? "" : "hide";
    }
    if (ctrltype == "time") {
      strclass = this.conditionsValue.time ? "" : "hide";
    }

    return strclass;
  }

  getValueColumn(colcount, ctrltype) {
    let colvalue = 0;
    if (
      ctrltype == "textbox" ||
      ctrltype == "paragraph" ||
      ctrltype == "name" ||
      ctrltype == "phone" ||
      ctrltype == "address" ||
      ctrltype == "website" ||
      ctrltype == "email" ||
      ctrltype == "matrixchoice"
    ) {
      colvalue = colcount == 10 ? 4 : 5;
    }
    if (
      ctrltype == "numberbox" ||
      ctrltype == "sliderquestiontype" ||
      ctrltype == "price"
    ) {
      colvalue = colcount == 10 ? 5 : 6;
    }
    if (
      ctrltype == "checkboxes" ||
      ctrltype == "singlechoice" ||
      ctrltype == "dropdown"
    ) {
      colvalue = colcount == 10 ? 6 : 7;
    }
    if (ctrltype == "date") {
      colvalue = colcount == 10 ? 7 : 8;
    }
    if (ctrltype == "time") {
      colvalue = colcount == 10 ? 8 : 9;
    }
    return colvalue;
  }

  getTagsData() {
    var taguser = document.getElementsByTagName("tag") as HTMLCollection;
    for (var i = 0; i < taguser.length; i++) {
      if (taguser[i].parentElement.classList.contains("tagsuser")) {
        //this.slcUserList.push(taguser[i].getAttribute("title"));
        this.slcUserList.push({
          id: taguser[i].getAttribute("id"),
          email: taguser[i].getAttribute("title"),
          name: taguser[i].getAttribute("name"),
        });
      }
      if (taguser[i].parentElement.classList.contains("tagsgroups")) {
        //this.slcUserGroups.push(taguser[i].getAttribute("title"));
        this.slcUserGroups.push({
          id: taguser[i].getAttribute("id"),
          role: taguser[i].getAttribute("role"),
          type: taguser[i].getAttribute("type"),
        });
      }
    }
  }
  getRuleData() {
    this.ruleslist.push([
      {
        rule_id: Math.random().toString(36).substr(2, 9),
        wait_for: this.form.value.rulefor,
        if_rejected: this.form.value.rulereject,
        approval_column: this.form.value.rulecolumn,
        approval_journal: this.form.value.rulejournal,
      },
    ]);
  }

  getScheculeData() {
    this.schedulelist = [];
    if (this.form.value.schedule == "yes") {
      this.schedulelist.push([
        {
          schedule: this.form.value.schedule,
          timezone_basedon: this.timezone_basedon,
          timezone:
            this.timezone_basedon === "no" ? "0" : this.form.value.timezone,
          duedate_basedon: this.duedate_basedon,
          relativedays: this.duedate_basedon === "2" ? this.relativedays : "0",
          duedate_days:
            this.duedate_basedon === "2" ? "" : this.form.value.duedate_days,
          duedate_hours:
            this.duedate_basedon === "2" ? "" : this.form.value.duedate_hours,
        },
      ]);
    }
  }

  clearTable() {
    $("#filerTable tr").each(function () {
      $(this)
        .find("td")
        .each(function () {
          if ($(this).hasClass("hide")) {
            $(this).remove();
          }
        });
    });
  }

  close() {
    this.dialogRef.close();
  }
}
