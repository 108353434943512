import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  AfterViewInit,
  ViewEncapsulation,
} from "@angular/core";
import { LayoutService, LayoutInitService } from "../../core";
import QFLayoutContent from "../../../../assets/js/layout/base/content";
import { QFUtil } from "../../../../assets/js/components/util";
import QFLayoutBrand from "../../../../assets/js/layout/base/brand";
import QFLayoutAside from "../../../../assets/js/layout/base/aside";
import QFLayoutAsideMenu from "../../../../assets/js/layout/base/aside-menu";
import QFLayoutAsideToggle from "../../../../assets/js/layout/base/aside-toggle";
import QFLayoutStickyCard from "../../../../assets/js/layout/base/sticky-card";
import QFLayoutStretchedCard from "../../../../assets/js/layout/base/stretched-card";
import QFLayoutHeader from "../../../../assets/js/layout/base/header";
import QFLayoutHeaderMenu from "../../../../assets/js/layout/base/header-menu";
import { Subscription, Observable, BehaviorSubject, ReplaySubject } from "rxjs";
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { NewworkflowComponent } from "src/app/modals/newworkflow/newworkflow.component";
import { MatPaginator } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";
import { WorkFlowService } from "src/app/services/work-flow.service";
import { ActivatedRoute, Router } from "@angular/router";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { DatePipe } from "@angular/common";
import { MatSort } from "@angular/material/sort";
import { ConfirmdeleteComponent } from "src/app/modals/confirmdelete/confirmdelete.component";
import { ToastrService } from "ngx-toastr";
import { CopyworkflowComponent } from "src/app/modals/copyworkflow/copyworkflow.component";
import { WorkflowhistoryComponent } from "src/app/modals/workflowhistory/workflowhistory.component";
import { FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { SalesforceModalComponent } from "src/app/modals/salesforce-modal/salesforce-modal.component";

@Component({
  selector: "app-salesforce",
  templateUrl: "./salesforce.component.html",
  styleUrls: ["./salesforce.component.scss"],
})
export class SalesforceComponent implements OnInit {
  addSalesforce: FormGroup;
  form_id: any = 0;
  headerLogo: string;
  selfLayout = "default";
  asideCSSClasses: string;
  headerCSSClasses: string;
  contentClasses = "";
  contentExtended: false;
  contentContainerClasses = "";
  asideSelfMinimizeToggle = false;
  disableAsideSelfDisplay: boolean;
  brandSkin: string;
  asideMenuCSSClasses: string;
  asideMenuScroll = 1;
  asideMenuDropdown;
  ulCSSClasses: string;
  brandClasses: string;
  headerContainerCSSClasses: string;
  headerMenuSelfDisplay: boolean;
  asideSelfDisplay: boolean;
  headerMenuHTMLAttributes: any = {};
  asideHTMLAttributes: any = {};
  headerMobileAttributes = {};
  headerHTMLAttributes: any = {};
  asideMenuHTMLAttributes: any = {};
  publisheddata: any[] = [];
  checkedoutdata: any[] = [];
  confirmFormDeleteDialogRef = null;
  uname: any;
  uaddress: any;
  uemail: any;
  uphone: any;
  qformid: any;
  activeTabId = 1;
  workdata: any[] = [];
  rechistory: any[] = [];
  chkpubCheckOut: any = [];
  salesforcedata: any = [];
  snowdata: any;
  talbename: any;
  tablecolumns: any;
  apiurl: any;
  apiheaders: any;
  apicolumns: any;
  data: any;
  access_token: any;
  arrheaders: any;
  fileName = "";
  encfileName = "";
  instanceurl: any;
  recordid: any = "";
  isupload: boolean = false;
  sysid: any;
  editdetails: any;
  dialogRef: any;

  @ViewChild("ktHeaderMenu", { static: true }) ktHeaderMenu: ElementRef;
  @ViewChild("ktAside", { static: true }) ktAside: ElementRef;
  @ViewChild("ktHeaderMobile", { static: true }) ktHeaderMobile: ElementRef;
  @ViewChild("ktHeader", { static: true }) ktHeader: ElementRef;
  loader$: Observable<number>;

  private loaderSubject: BehaviorSubject<number> = new BehaviorSubject<number>(
    0
  );
  private unsubscribe: Subscription[] = [];

  constructor(
    private dialog: MatDialog,
    private initService: LayoutInitService,
    private layout: LayoutService,
    public api: WorkFlowService,
    private route: ActivatedRoute,
    public router: Router,
    private toastr: ToastrService,
    private ngxService: NgxUiLoaderService,
    private fb: FormBuilder,
    public datepipe: DatePipe
  ) {
    this.initService.init();

    this.addSalesforce = fb.group({
      uname: new FormControl(),
      uaddress: new FormControl(),
      uemail: new FormControl(),
      uphone: new FormControl(),
    });
  }

  ngOnInit() {
    this.selfLayout = this.layout.getProp("self.layout");
    this.brandClasses = this.layout.getProp("brand");
    this.asideCSSClasses = this.layout.getStringCSSClasses("aside");
    this.headerCSSClasses = this.layout.getStringCSSClasses("header");
    this.contentClasses = this.layout.getStringCSSClasses("content");
    this.contentExtended = this.layout.getProp("content.extended");
    this.contentContainerClasses =
      this.layout.getStringCSSClasses("content_container");
    this.headerLogo = this.getLogoURL();
    this.disableAsideSelfDisplay =
      this.layout.getProp("aside.self.display") === false;
    this.brandSkin = this.layout.getProp("brand.self.theme");
    this.asideMenuCSSClasses = this.layout.getStringCSSClasses("aside_menu");
    this.asideMenuHTMLAttributes = this.layout.getHTMLAttributes("aside_menu");
    this.asideMenuScroll = this.layout.getProp("aside.menu.scroll") ? 1 : 0;
    this.asideMenuDropdown = this.layout.getProp("aside.menu.dropdown")
      ? "1"
      : "0";
    this.ulCSSClasses = this.layout.getProp("aside_menu_nav");
    this.headerContainerCSSClasses =
      this.layout.getStringCSSClasses("header_container");
    this.headerMenuSelfDisplay = this.layout.getProp(
      "header.menu.self.display"
    );
    this.asideSelfDisplay = this.layout.getProp("aside.self.display");
    this.asideHTMLAttributes = this.layout.getHTMLAttributes("aside");
    this.headerMobileAttributes =
      this.layout.getHTMLAttributes("header_mobile");
    this.headerHTMLAttributes = this.layout.getHTMLAttributes("header");
    this.asideSelfMinimizeToggle = this.layout.getProp(
      "aside.self.minimize.toggle"
    );

    this.getSalesforceCredentials();
    this.generateAccessToken();
  }

  private getLogoURL(): string {
    let result = "logo.png";
    return `../../../../assets/media/logos/${result}`;
  }

  onFileSelected(event) {
    const file: File = event.target.files[0];
    if (file) {
      this.isupload = true;
      this.fileName = file.name;
      debugger;
      this.convertDocument(event.target.files[0]).subscribe((base64) => {
        console.log(base64);
        this.encfileName = base64;
      });
    }
  }

  convertDocument(file: File): Observable<string> {
    const result = new ReplaySubject<string>(1);
    const reader = new FileReader();
    reader.readAsBinaryString(file);
    reader.onload = (event) => result.next(btoa(reader.result.toString()));
    return result;
  }

  getSalesforceCredentials() {
    let workflowid = "61a709351c3a0000340022e3";
    let nodeid = "node-13";
    this.api.getRunScriptDetails(workflowid, nodeid).subscribe((res: any) => {
      this.apiurl = res.runscript_data[0].apiurl;
      this.apiheaders = res.runscript_data[0].apiheaders;
      this.apicolumns = res.runscript_data[0].apicolumns;
      this.generateAccessToken1(this.apiheaders);
    });
  }
  generateAccessToken() {
    let strurl =
      "https://login.salesforce.com/services/oauth2/token?grant_type=password&client_id=3MVG9pRzvMkjMb6mdQn1Vk6UWZf_SgUUpQ1h7dlrOZDU_Xqa.Ip8S1EmQATwQ3AQvg4yoQkIh.g1_CDqT4DMe&client_secret=94DE91B3E7457E6EBA7F53EB38887C181BCFA5A62469517258E31A39351874DD&username=wanimudasir@yahoo.co.in&password=0Cdd9d3b75///WaXE2m2y80U5z5L7ldSVDPJEy";

    this.api.getSalesforcetoken(strurl).subscribe(
      (res: any) => {
        this.instanceurl = res.instance_url;
        this.access_token = res.access_token;
        this.getSalesforceData(this.access_token);
      },
      (err) => {
        console.log(err);
        this.ngxService.stop();
      }
    );
  }
  generateAccessToken1(apiheaders) {
    apiheaders = apiheaders.replace(/(\r\n|\n|\r)/gm, "");
    this.arrheaders = JSON.parse(apiheaders);
    let client_id = this.arrheaders.client_id;
    let client_secret = this.arrheaders.client_secret;
    let username = this.arrheaders.username;
    let password = this.arrheaders.password;
    let security_token = this.arrheaders.security_token;
    let strurl =
      "https://login.salesforce.com/services/oauth2/token?grant_type=password&client_id=" +
      client_id +
      "&client_secret=" +
      client_secret +
      "&username=" +
      username +
      "&password=" +
      password +
      security_token;

    this.api.getSalesforcetoken(strurl).subscribe(
      (res: any) => {
        this.instanceurl = res.instance_url;
        this.access_token = res.access_token;
        this.getSalesforceData(this.access_token);
      },
      (err) => {
        console.log(err);
        this.ngxService.stop();
      }
    );
  }
  getSalesforceData(access_token) {
    let apiurl =
      this.instanceurl +
      "/services/data/v53.0/query/?q=SELECT+Name,+Site,+OwnerId,+Phone,+Type,+qfexternal_id__c+FROM+Account";
    let apiheader = '{"Authorization":"Bearer ' + access_token + '"}';
    this.salesforcedata = [];
    this.api.getSalesforceData(apiurl, apiheader).subscribe(
      (res: any) => {
        if (res.totalSize > 0) {
          res.records.forEach((element) => {
            this.salesforcedata.push({
              _id: element.attributes.url,
              name: element.Name,
              site: element.Site,
              phone: element.Phone,
              type: element.Type,
              external_id: element.qfexternal_id__c,
            });
          });
        }
      },
      (err) => {
        console.log(err);
        this.ngxService.stop();
      }
    );
  }
  genExternalId(length) {
    var result = "";
    var characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  genSysId(externalid, funmode) {
    let apiurl =
      this.instanceurl +
      "/services/data/v53.0/query/?q=SELECT+Id,+Name,+Site,+OwnerId,+Phone,+Type,+qfexternal_id__c+FROM+Account+where+qfexternal_id__c+in('" +
      externalid +
      "')";
    let apiheader = '{"Authorization":"Bearer ' + this.access_token + '"}';
    this.api.getSalesforceData(apiurl, apiheader).subscribe(
      (res: any) => {
        if (res.totalSize > 0) {
          res.records.forEach((element) => {
            this.setFunction(element.attributes.url, funmode);
          });
        }
      },
      (err) => {
        console.log(err);
        this.ngxService.stop();
      }
    );
  }

  setFunction(sysid, funmode) {
    funmode == "delete" ? this.deleteData(sysid) : this.EditData(sysid);
  }

  saveData() {
    let arrcolumns = this.apicolumns.split(",");
    const snowdata = {};

    arrcolumns.forEach((element) => {
      let icatcol = element.split("+");
      icatcol.forEach((ielement) => {
        if (ielement == "Salutation") {
          snowdata[ielement] = "Mr";
        }
        if (ielement == "FirstName") {
          let firstname = this.addSalesforce.value.uname;
          firstname = firstname.split(" ");
          snowdata[ielement] = firstname[0];
        }
        if (ielement == "LastName") {
          let lastname = this.addSalesforce.value.uname;
          lastname = lastname.split(" ");
          snowdata[ielement] = lastname[1];
        }
      });
      if (element == "Name") {
        snowdata[element] = this.addSalesforce.value.uname;
      }
      if (element == "Site") {
        snowdata[element] = this.addSalesforce.value.uaddress;
      }
      if (element == "Type") {
        snowdata[element] = this.addSalesforce.value.uemail;
      }
      if (element == "Phone") {
        snowdata[element] = this.addSalesforce.value.uphone;
      }
      if (element == "Company") {
        snowdata[element] = this.addSalesforce.value.uemail;
      }
      if (element == "qfexternal_id__c") {
        snowdata[element] = this.genExternalId(24);
      }
    });

    let apiheader = '{"Authorization":"Bearer ' + this.access_token + '"}';
    this.api.saveSalesforce(this.apiurl, apiheader, snowdata).subscribe(
      (res: any) => {
        this.ngxService.stop();
        if (res.success) {
          this.recordid = res.id;
          if (this.isupload) {
            this.uploadDoc();
          }
          this.toastr.success(
            "Salesforce Record saved successfully",
            "Success"
          );
          this.getSalesforceData(this.access_token);
        } else {
          this.toastr.error("Saving Salesforce failed! ", "Error!");
        }

        this.ngxService.stop();
      },
      (err) => {
        this.ngxService.stop();
        console.log(err);
      }
    );
  }
  EditRecord(externalid) {
    this.genSysId(externalid, "Edit");
  }
  EditData(sysid) {
    let apiurl = this.instanceurl + "/" + sysid;
    let apiheader = '{"Authorization":"Bearer ' + this.access_token + '"}';
    this.api.getSalesforceData(apiurl, apiheader).subscribe(
      (res: any) => {
        this.editdetails = res;
        this.EditDialog(this.editdetails);
      },
      (err) => {
        console.log(err);
        this.ngxService.stop();
      }
    );
  }

  EditDialog(editdetails) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = "100%";
    dialogConfig.minHeight = "calc(100vh - 90px)";
    dialogConfig.height = "auto";
    dialogConfig.data = {
      sysid: editdetails.attributes.url,
      instanceurl: this.instanceurl,
      uname: editdetails.Name,
      usite: editdetails.Site,
      uphone: editdetails.Phone,
      utype: editdetails.Type,
      apiurl: this.apiurl,
      accesstoken: this.access_token,
      apicolumns: this.apicolumns,
    };
    this.dialogRef = this.dialog.open(SalesforceModalComponent, dialogConfig);

    this.dialogRef.afterClosed().subscribe(
      (query) => {
        if (typeof query !== "undefined") {
          setTimeout(() => {
            this.getSalesforceData(this.access_token);
          }, 2000);
        }
      },
      (error) => {
        console.error("Error while closing the dialog ", error);
      }
    );
  }

  uploadDoc() {
    let apiurl = this.instanceurl + "/services/data/v45.0/sobjects/Attachment/";
    let apiheader = '{"Authorization":"Bearer ' + this.access_token + '"}';

    const docdata = {
      Name: this.fileName,
      body: this.encfileName,
      parentId: this.recordid,
    };
    this.api.saveSalesforce(apiurl, apiheader, docdata).subscribe(
      (res: any) => {
        this.ngxService.stop();
        if (res.success) {
          this.toastr.success("Document Uploaded successfully", "Success");
        } else {
          this.toastr.error("Document Upload failed! ", "Error!");
        }

        this.ngxService.stop();
      },
      (err) => {
        this.ngxService.stop();
        console.log(err);
      }
    );
  }
  deleteRecord(externalid) {
    this.genSysId(externalid, "delete");
  }
  deleteData(sysid) {
    let apiurl = this.instanceurl + sysid;
    let apiheader = '{"Authorization":"Bearer ' + this.access_token + '"}';

    this.api.deleteSalesforce(apiurl, apiheader).subscribe(
      (res: any) => {
        this.ngxService.stop();
        if (res == "" || res == null) {
          this.toastr.success(
            "Salesforce Record Deleted successfully",
            "Success"
          );
          this.getSalesforceData(this.access_token);
        } else {
          this.toastr.error("Deleting Salesforce failed! ", "Error!");
        }
        this.ngxService.stop();
      },
      (err) => {
        this.ngxService.stop();
        console.log(err);
      }
    );
  }

  ngAfterViewInit() {
    if (this.ktHeaderMenu) {
      for (const key in this.headerMenuHTMLAttributes) {
        if (this.headerMenuHTMLAttributes.hasOwnProperty(key)) {
          this.ktHeaderMenu.nativeElement.attributes[key] =
            this.headerMenuHTMLAttributes[key];
        }
      }
    }

    if (this.ktAside) {
      for (const key in this.asideHTMLAttributes) {
        if (this.asideHTMLAttributes.hasOwnProperty(key)) {
          this.ktAside.nativeElement.attributes[key] =
            this.asideHTMLAttributes[key];
        }
      }
    }

    if (this.ktHeaderMobile) {
      for (const key in this.headerMobileAttributes) {
        if (this.headerMobileAttributes.hasOwnProperty(key)) {
          this.ktHeaderMobile.nativeElement.attributes[key] =
            this.headerMobileAttributes[key];
        }
      }
    }

    if (this.ktHeader) {
      for (const key in this.headerHTMLAttributes) {
        if (this.headerHTMLAttributes.hasOwnProperty(key)) {
          this.ktHeader.nativeElement.attributes[key] =
            this.headerHTMLAttributes[key];
        }
      }
    }
    QFLayoutContent.init("kt_content");
    QFUtil.ready(() => {
      QFLayoutHeader.init("kt_header", "kt_header_mobile");
      QFLayoutHeaderMenu.init("kt_header_menu", "kt_header_menu_wrapper");
      QFLayoutBrand.init("kt_brand");
      QFLayoutAside.init("kt_aside");
      QFLayoutAsideMenu.init("kt_aside_menu");
      if (this.asideSelfMinimizeToggle) {
        QFLayoutAsideToggle.init("kt_aside_toggle");
      }
      QFLayoutStickyCard.init("kt_page_sticky_card");
      QFLayoutStretchedCard.init("kt_page_stretched_card");
    });
  }
}
