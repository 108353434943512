import { Component, Inject, OnInit, ViewChild } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { WorkFlowService } from "src/app/services/work-flow.service";

export interface FormsData {
  userNumber: number;
  name: string;
  publishedby: string;
  publishedon: string;
  ispublished: string;
  _id: string;
}
@Component({
  selector: "app-workflowhistory",
  templateUrl: "./workflowhistory.component.html",
  styleUrls: ["./workflowhistory.component.scss"],
})
export class WorkflowhistoryComponent implements OnInit {
  formid: any;
  recid: any;
  workflowid: any;
  workflowname: any;
  @ViewChild("matpaginator", { static: true }) matpaginator: MatPaginator;

  displayedColumns = [
    "userNumber",
    "name",
    "publishedby",
    "publishedon",
    "ispublished",
  ];
  TABLE_DATA: FormsData[] = [];
  dataSource = new MatTableDataSource<FormsData>(this.TABLE_DATA);
  pager: any = {};
  length: number = 0;
  currentPage: number = 1;
  serial: number = 0;
  pagedItems: any[];
  pageSizeOptions: number[] = [10, 20, 50, 100];
  data = [];
  pageSize = 10;

  constructor(
    private api: WorkFlowService,
    private ngxService: NgxUiLoaderService,
    private toastr: ToastrService,
    public router: Router,
    private dialogRef: MatDialogRef<WorkflowhistoryComponent>,
    @Inject(MAT_DIALOG_DATA)
    { recid, formid, workflowname, workflowid }
  ) {
    this.recid = recid;
    this.formid = formid;
    this.workflowname = workflowname;
    this.workflowid = workflowid;
  }

  ngOnInit(): void {
    this.getDataList(this.recid, this.formid, this.workflowid);
  }

  getDataList(recid, formid, workflowid) {
    this.api
      .getHistoryList(
        recid,
        formid,
        workflowid,
        this.currentPage,
        this.pageSize
      )
      .subscribe(
        (res: any) => {
          this.ngxService.stop();
          res.history_list.data.map((row, i) => {
            row["userNumber"] = res.history_list.total - i;
          });
          if (res && res.history_list) {
            this.length = res.history_list.total;
          }
          this.TABLE_DATA = [];
          let reccount = 0;
          res.history_list.data.forEach((element) => {
            let row = {
              userNumber: element.userNumber,
              name: element.form_name,
              publishedby: localStorage.getItem("createdBy"),
              publishedon: element.updated_at,
              ispublished: element.status,
              _id: element._id,
            };
            if (element.status == false) {
              this.TABLE_DATA.push(row);
              reccount += 1;
            }
          });
          //this.length = reccount;
          this.dataSource.data = this.TABLE_DATA;
        },
        (err) => {
          console.log(err);
        }
      );
  }

  handlePage(e: any) {
    this.ngxService.start();
    this.currentPage = e.pageIndex + 1;
    this.pageSize = e.pageSize;
    this.getDataList(this.recid, this.formid, this.workflowid);
  }

  close() {
    this.dialogRef.close();
  }
}
